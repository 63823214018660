// Container para os textos do contador
.number-wrap {
    text-align: center;
    padding: 5px 0px 13px 0px;
    margin: 0;
    min-width: 118px;
    border-radius: 2px;
    max-width: 100%;
}

// Números do contador
.number-counter {
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    text-transform: none;
    letter-spacing: -2px;
    word-spacing: 0;
    line-height: 1.3;
    color: $primary-color;
}

// Textos do contador 
.text-counter {
    display: block;
    padding-top: 2px;
}