#ganhador-title {
    text-transform: uppercase;
}

#ganhador-banner {
    background-image: url($public-url + "/images/ganhador-desktop.jpg"); 
    min-height: 100vh; 
    padding-left: 2em; 
    padding-right: 2em; 
    height:auto
}

@media only screen and (max-width: 546px) {
    #ganhador-banner {
        background-image: url($public-url + "/images/ganhador-mobile.jpg"); 
    }
}