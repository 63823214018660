@media (min-width: 768px) {
	.dashboard-body-admin #dashboard-body {
		margin-top: 70px;
	}
}

#dashboard-body {
	min-height: 100vh;

	#page-body-content {
		padding: 0px 12px;
	}

	.content {
		min-height: 100vh;

		.content-body {
			padding: 15px 15px 10px 15px;
			background-color: #fff;
			border: 0;
			border-radius: 0.1875rem;
			display: inline-block;
			position: relative;
			width: 100%;
			margin-bottom: 20px;
			box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
			overflow-x: auto;
		}
  }
}

@media (max-width: 546px) {
	#dashboard-body .content {
		padding: 0 !important;
	}
}