.icone {
    margin-right: 0.5em;
    color: #6c757d;
}

.icone-compartilhar {
    height: 30px;
    width: 40px;
    border: 0;
    font-size: 0 !important;
}

.icone-redes-sociais-organizador, .icone-redes-sociais-organizador:hover {
    color: $primary-color;
    font-size: 30px;
}

.icon_novo_dashboard {
    background: url($path-pasta-icones + "/novo-22.png") no-repeat;
}

.icon_voltar_seta_preta_dashboard{
    background: url($path-pasta-icones + "/voltar-preto-22.png") no-repeat;
}

.icon_excel_dashboard {
    background: url($path-pasta-icones + "/excel-22.png") no-repeat;
  }

.icon_sortear {
    background: url($path-pasta-icones + "/sortear-30.png") no-repeat;
}

.icon_liberar_acesso {
    background: url($path-pasta-icones + "/share-solid.svg") no-repeat;
    height: 20px;
}

.icon_alert {
    background: url($path-pasta-icones + "/alert.svg") no-repeat;
}