$primary-blue: #0051BA;
$primary-orange: #ff5e14;
$primary-yellow: #FFDA1A;
$primary-color: $primary-blue;
$secondary-color: $primary-yellow;
$primary-font-color: #fff;
$secondary-font-color: #000;
$link-font-color: $primary-color;
$nav-link-color: $secondary-color;
$btn-acao-color: $primary-color;
$light-font-color: #fff;
$footer-background-color: #24273a;
$public-url: "https://www.rifatech.com/app";
$path-pasta-icones: "#{$public-url}/images/icons";
$path-cover-img-campanha: "#{$public-url}/images/rifa-cover-placeholder.jpg";

// Dashboard Menu
$dashboard-menu-primary-color: $primary-color;
$dashboard-menu-secondary-color: #003172;
$dashboard-menu-item: #cedce4;
$dashboard-menu-item-hover: #ffffff;
$dashboard-menu-item-active: #ffffff;

// Variáveis usadas na página comprar_retorno.xhtml
$background-aviso-pagamento: $primary-color;
$background-on-hover: $secondary-color; // cor do fundo quando o usuário passa o mouse em cima do card
$color-on-hover: $primary-color; // cor do texto quando o usuário passa o mouse em cima do card