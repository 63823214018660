.left-side-menu {
	display: block;
}

@media (max-width: 767.98px) {
	.left-side-menu {
		box-shadow: 0px 2px 22px 0 rgba(0, 0, 0, 0.2), 0px 2px 30px 0 rgba(0, 0, 0, 0.35);
	}
}

.btn-acao-rifa {
	box-shadow: none;
}

.btn-paginator-mobile {
	box-shadow: none;
}

#dashboard-nav .topbar-right-menu {
	margin-top: 20px;
}

.card label {
	font-size: inherit;
}

.card-body {
	overflow-x: auto;
}

.left-side-menu {
	background: $dashboard-menu-primary-color;
	background-color: $dashboard-menu-primary-color;
	overflow-y: auto;
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
	padding-top: 0;
	
	.logo {
		position: relative;
	}
}

.left-side-menu::-webkit-scrollbar { 
	display: none;  /* Safari and Chrome */
}

.btn-primary {
	box-shadow: 0px 2px 6px 0px rgba($primary-color, 0.5) !important;
}

.btn-secondary {
	box-shadow: 0px 2px 6px 0px rgba($secondary-color, 0.5) !important;
}

.btn-acao-rifa, .btn-paginator-mobile {
	background-color: #fff !important;
	color: $primary-blue !important;
	box-shadow: none !important;
}

.navbar-toggler {
	border: 0;
}