#dashboard-nav {
	display: block;

	@media screen and (min-width: 768px) {
		a {
			margin-left: 0.25rem;
		}
	}

	#navbar-admin-mobile {
		padding: 1em 1em 1em 1em;
		background-color: white !important;

		.btn-primary {
			display: block;
			margin: 1em 0 0 0;
			width: 100%;
		}
	}
}

.navbar-custom {
	left: 260px !important;
}