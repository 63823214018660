#grafico-pageviews-analytics {
	.nav-item.active {
		color: $primary-color !important;
	  }
	  
	.nav-link:hover {
		color: $primary-color !important;
		cursor: pointer;

		&::before {
			visibility: hidden;
	  }
	}
}