#table-pagamento {
    height: 100%; 
    min-width: 0 !important;
}

#parceiro-select {
    width: 250px;
    margin: 0 auto;
}

// Elemento input para a quantidade de rifas a serem compradas
#comprar-rifa .input-qtd-rifa {
    height: 38px;
    border: 1px solid #d9d9d9;
    border-radius: 0;
}

#comprar-rifa .form-group {
    display: inline !important;
}

#comprar-rifa .stepper_handle-counter { 
    overflow: hidden; 
}

#comprar-rifa .stepper_handle-counter .stepper_counter-minus,  
#comprar-rifa .stepper_handle-counter .stepper_counter-plus,  
#comprar-rifa .stepper_handle-counter input {
    float: left;
    text-align: center;
}

#comprar-rifa .stepper_handle-counter .stepper_counter-minus,  
#comprar-rifa .stepper_handle-counter .stepper_counter-plus { 
    text-align: center; 
}

#comprar-rifa .stepper_handle-counter input {
    width: 50px;
    border-width: 1px;
    border-left-width: 0;
    border-right-width: 0;
}

#comprar-rifa .stepper_btn {
    padding: 6px 12px;
    border: 1px solid transparent;
    color: #fff;
}

#comprar-rifa .stepper_btn-primary { 
    background-color: #009dda; 
}

#comprar-rifa .stepper_btn-primary:hover, .stepper_btn-primary:focus { 
    background-color: #0486b9; 
}

@media (max-width: 545px) {
    #comprar-rifa .botao-quantidade {
        font-weight: normal !important;

        .texto-bilhete {
            font-size: small;
            display: block;
        }
    }
}