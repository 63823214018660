.dashboard-menu-lateral {
	position: fixed;
	top: 0;
	height: 100%;
	bottom: 0;
	width: 260px;
	left: 0;
	z-index: 1030;
	background-color: $primary-color;
	color: $secondary-color;
	box-shadow: 0px 2px 22px 0 rgba(0, 0, 0, 0.2), 0px 2px 30px 0 rgba(0, 0, 0, 0.35);

	-webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
	-moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
	-o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
	-ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
	transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.dashboard-menu-lateral .logo .simple-text {
	text-align: center;
	text-transform: uppercase;
	padding: 0.5rem 0;
	display: block;
	white-space: nowrap;
	font-size: 1em;
	font-weight: 600 !important;
	color: $secondary-color;
	text-decoration: none;
	font-weight: 400;
	line-height: 30px;
	overflow: hidden;
}

.dashboard-menu-lateral .logo {
	position: relative;
   padding: 0.5rem 0.7rem;
   z-index: 4;
}

.dashboard-menu-lateral .logo:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 15px;
  height: 1px;
  width: calc(100% - 30px);
  background-color: $secondary-color;
}

.dashboard-menu-lateral .sidebar-wrapper {
	padding-top: 1em;
}

.dashboard-menu-lateral li>a {
	margin: 10px 15px 0;
	border-radius: 30px;
	color: $secondary-color;
	display: block;
	text-decoration: none;
	position: relative;
	text-transform: uppercase;
	cursor: pointer;
	font-weight: 600;
	font-size: 0.875rem;
	padding: 10px 8px;
	line-height: 1.5rem;
	transition: all .34s;
	-webkit-transition: all .34s;
	-moz-transition: all .34s;
}

.dashboard-menu-lateral li {
	width: 100%;
}

.dashboard-menu-lateral li:hover:not(.active)>a,
.dashboard-menu-lateral li:focus:not(.active)>a {
  background-color: rgba(255, 218, 26,0.7);;
  color: $primary-color;
}

.dashboard-menu-lateral li:hover:not(.active)>a i,
.dashboard-menu-lateral li:focus:not(.active)>a i {
  color: $primary-color;
}

.dashboard-menu-lateral li.active>a {
  background-color: $secondary-color;
  color: $primary-color;
  box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
}

.dashboard-menu-lateral i {
	font-size: 20px;
	float: left;
	margin-right: 12px;
	line-height: 30px;
	width: 34px;
	text-align: center;
}

.dashboard-menu-lateral .dashboard-menu-item {
	line-height: 30px;
}

.version-container {
	color: #fff;
	position: absolute;
	bottom: 0.5rem;
	left: 0.5rem;

	a {
		color: #cedce4;

		&:hover {
			color: #fff;
		}
	}
}