.image-editor-container {
	margin-top: 1.5em;

	.rotate-buttons-container {
		margin-bottom: 1em;
		position: relative;
		z-index: 999;
	}

	.rotate-button,
	.rotate-button:hover,
	.rotate-button:active,
	.rotate-button:focus,
	.rotate-button:visited {
		font-size: 2em;
		color: $primary-color !important;
		background-color: #fff !important;
		border: 0 !important;
		box-shadow: 0 0 0 0 #fff !important;
	}

	.image-container {
		max-width: 100%;
		display: flex;
	}

	.image-preview {
		object-fit: contain;
		margin: 0 auto;
	}
}
