
// Remove a cor verde quando o campo do input é válido
.form-control.is-valid, 
.was-validated .form-control:valid {
    border-color: #ced4da
}

// Remove a cor verde quando o campo do radio button é válido
.form-check-input.is-valid~.form-check-label, 
.was-validated .form-check-input:valid~.form-check-label {
    color: #212529
}

@media (min-width: 991px) {
    .form-container {
        max-width: 70%;
        margin: auto;
    }
}

.form-container-sm {
    max-width: 450px;
    margin: auto;
}

// Diminui as margens de páginas de formulário em celulares
@media (max-width: 545px) {
    .form-container {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.radio-forma-pagamento .form-check-input {
    margin-bottom: 8px;
}