.icone-fundo-colorido {
    border-radius: 50%;
    color: #fff;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 50px;
    width: 50px;
    display: inline-block;
    padding: 8px 10px;
    font-size: 0;
}

.icon_share_link {
    background: url($path-pasta-icones + "/share-alt-solid.svg") no-repeat;
    margin-left: -1px;
}

.icon_facebook {
    background: url($path-pasta-icones + "/facebook-f-brands.svg") no-repeat;
    margin-left: 6px;
}

.icon_instagram {
    background: url($path-pasta-icones + "/instagram-brands.svg") no-repeat;
}

.icon_twitter {
    background: url($path-pasta-icones + "/twitter-brands.svg") no-repeat;
}

.icon_whatsapp {
    background: url($path-pasta-icones + "/whatsapp-brands.svg") no-repeat;
    margin-top: -1px;
    margin-left: 2px;
}