.card-img {
    height: 40px;
    width: 40px;
}

.card-wrapper {
    height: 100%;
    padding-bottom: 1rem;
    background: #ffffff;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.card-title {
    font-size: calc(1.5rem * 0.9);
    font-weight: bold;
    color: $primary-color;
    
    &::after {
        content: "";
        display: block;
        background-color: $background-on-hover;
        height: 3px;
        width: 25px;
        margin: auto;
        margin-top: 15px;
    }
}

// Para telas pequenas/médias
@media (max-width: 991px) {
    .title-common {
        font-weight: 600;
        font-size: 2.4rem;
        font-size: calc( 1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20)));
        line-height: calc( 1.4 * (1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20))));
    }
    
    .card-pagamento:not(.last-child) {
        border-right: none;
        border-bottom: 1px solid #cccccc;
    }
}

// Para telas grandes
@media (min-width: 992px) {
    .card-pagamento:not(.last-child) {
        border-bottom: none;
        border-right: 1px solid #cccccc;
    }
    
    .card-pagamento {
        // Efeitos quando o usuário passa o mouse em cima do card
        &:hover {
            background-color: $background-on-hover;
            color: $color-on-hover;

            .card-title {
                color: $color-on-hover;
                
                &::after {
                    background-color: $color-on-hover;
                    width: 50%;
                    -webkit-transition: all 0.3s linear;
                    -o-transition: all 0.3s linear;
                    transition: all 0.3s linear;
                }
            }
        }

        height: 100%;
        padding: 15px;
        -webkit-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
    }
}

#pagamento-aviso-container {
    padding-top: 45px;
    padding-bottom: 75px;
    background-color: $background-aviso-pagamento;
    color: $primary-font-color;
}

.icone-pagamento {
    font-size: 2.5rem;
    width: 2.5rem;
    color: $primary-color;
}

#button-boleto {
    color: #000 !important;
}