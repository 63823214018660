$button-height: 30px;
$button-width: 2*$button-height;

#switch-button {
	width: $button-width;
	display: inline-flex;

	.switch {
		visibility: hidden;
		position: absolute;
		margin-left: -9999px;
	}
	
	.switch + label {
		display: block;
		position: relative;
		cursor: pointer;
		outline: none;
		user-select: none;
	}
	
	.switch--shadow + label {
		padding: 2px;
		width: $button-width;
		height: $button-height;
		background-color: #dddddd;
		border-radius: $button-height;
	}
	.switch--shadow + label:before,
	.switch--shadow + label:after {
		display: block;
		position: absolute;
		top: 1px;
		left: 1px;
		bottom: 1px;
		content: "";
	}
	.switch--shadow + label:before {
		right: 1px;
		background-color: #f1f1f1;
		border-radius: $button-height;
		transition: background 0.4s;
	}
	.switch--shadow + label:after {
		width: $button-height;
		background-color: #fff;
		border-radius: 100%;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
		transition: all 0.4s;
	}
	.switch--shadow:checked + label:before {
		background-color: #8ce196;
	}
	.switch--shadow:checked + label:after {
		transform: translateX($button-height);
	}
}