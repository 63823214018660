$altura-foto-organizador: 150px;
$altura-foto-organizador-celular: 70px;

// Imagem de capa da rifa
#rifa-cover-img {
    width: 100%;
    height: 30vh;
    background-image: url($path-cover-img-campanha);
    background-size: cover;
    background-position: top;
}

.img-organizador {
    height: $altura-foto-organizador;
    width: $altura-foto-organizador;
    margin-top: -$altura-foto-organizador/2;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    background-color: #fff;
}

// Define o tamanho da foto do organizador em celulares
@media screen and (max-width: 546) {
    .img-organizador {
        height: $altura-foto-organizador-celular !important;
        width: $altura-foto-organizador-celular !important;
        margin-top: -$altura-foto-organizador-celular/2 !important;
    }
}

#nome-beneficiado {
    font-size: 1.5rem;
}

// Elemento input para a quantidade de rifas a serem compradas
.input-qtd-rifa {
    height: 38px;
    border: 1px solid $primary-color;
}