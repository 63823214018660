.btn-acao-rifa, 
.btn-acao-rifa:active, 
.btn-acao-rifa:visited,
.btn-acao-rifa:hover, 
.btn-acao-rifa:focus, 
.btn-acao-rifa.focus  {
	background-color: transparent !important;
	border-color: $primary-color !important;
	color: $btn-acao-color !important;
	display: block !important;
	padding: 0  !important;
	border: 0 !important;
	margin-bottom: 0.75em  !important;
	text-align: left  !important;
}

a {
	text-decoration: none !important;
}