$cor-destaque: $primary-color; 

.tabela-container {
	width: 98%;
	margin: 0 auto;

	table {
		min-width: 100%;
		width: auto;
	}
}

.sortable-column {
	cursor: pointer;
}

.sortable-icon {
	margin: 0 0 0 1em;
	background-image: url($path-pasta-icones + "/sort-solid.svg");
	background-position: right center, left top;
	background-repeat: no-repeat;
	width: 20px;
	height: 15px;
	display: inline-flex;
}

.sorted-icon-asc {
	background-image: url($path-pasta-icones + "/sort-up-solid.svg");
}

.sorted-icon-desc {
	background-image: url($path-pasta-icones + "/sort-down-solid.svg");
}

// ÍCONES DE AÇÃO DA RIFA
.icone {
	display: block;
}

.icon_ver_rifa {
	background: url($path-pasta-icones + "/pesquisar-22.png") no-repeat;
	background-color: white;
}

.icon_edita_rifa {
	background: url($path-pasta-icones + "/editar-22.png") no-repeat;
	background-color: white;
}

.icon_deletar {
	background: url($path-pasta-icones + "/excluir-22.png") no-repeat;
	background-color: white;
}


.icon_ver_bilhetes_rifa {
	background: url($path-pasta-icones + "/tickets2_26.png") no-repeat;
	background-color: white;
}

.icon_comprovante_rifa {
	background: url($path-pasta-icones + "/icon_compravante_pagamento_22.png") no-repeat;
	background-color: white;
	min-height: 40px;
}

.icon_comprovante_nfse_rifa {
	background: url($path-pasta-icones + "/icon_comprovante_nfse_22.png") no-repeat;
	background-color: white;
	min-height: 40px;
}