#table-paginator {
	background-color: #ffffff;
	padding: 0 !important;
	display: flex;
	margin: 0 2em;;
	font-size: 1em;
}

.pagination {
	margin-bottom: 0;
	display: inline-flex;
	padding: 0 1.5em;
}

.page-link {
	border: 0;
	color: $primary-color;
}

.page-item.active .page-link {
	z-index: 1;
	color: $primary-font-color !important;
	background-color: $primary-color;
	border-color: $primary-color;
}

#page-size-form-group {
	display: inline-flex;
	margin-bottom: 0;
}

#page-size-select {
	width: 5.2em;
	border: 0;
}

#lista-compartilhamentos-container #table-paginator {
	margin: 0 12px;
}