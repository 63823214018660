#facebook-login-button {
	display: inline-flex;
	margin-right: 1em;

	/* Shared */
	.loginBtn {
		box-sizing: border-box;
		position: relative;
		/* width: 13em;  - apply for fixed size */
		margin: 0.2em;
		padding: 0 15px 0 46px;
		border: none;
		text-align: left;
		line-height: 34px;
		white-space: nowrap;
		border-radius: 0.2em;
		font-size: 16px;
		color: #FFF;
		height: 43px;
	}
	.loginBtn:before {
		content: "";
		box-sizing: border-box;
		position: absolute;
		top: 5px;
		left: 0;
		width: 34px;
		height: 100%;
	}
	.loginBtn:focus {
		outline: none;
	}
	.loginBtn:active {
		box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
	}
 
	/* Facebook */
	.loginBtn--facebook {
		background-color: #4C69BA;
		background-image: linear-gradient(#4C69BA, #3B55A0);
		text-shadow: 0 -1px 0 #354C8C;
	}
	.loginBtn--facebook:before {
		background: url($path-pasta-icones + '/icon_facebook.png') 6px 6px no-repeat;
	}
	.loginBtn--facebook:hover,
	.loginBtn--facebook:focus {
		background-color: #5B7BD5;
		background-image: linear-gradient(#5B7BD5, #4864B1);
	}
}

#facebook-login {
	border-color: #4C69BA;
	color: #4C69BA;
}

#google-login {
	border-color: #DB4437;
	color: #DB4437;
}