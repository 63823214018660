h5 {
	font-size: 1.57em;
   line-height: 1.4em;
	margin-bottom: 15px;
	font-weight: 700;
}

.content {
	padding: 0 30px 30px;
	min-height: calc(100vh - 123px);
	margin-top: 30px;
	//background-color: #ebecf1;
}

.card {
	border: 0;
	border-radius: 0.1875rem;
	display: inline-block;
	position: relative;
	width: 100%;
	max-width: 100%;
	margin-bottom: 20px;
	box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
 }
 
 .card .card-body {
	padding: 15px 15px 10px 15px;
 }
 
 .card .card-body.table-full-width {
	padding-left: 0;
	padding-right: 0;
 }
 
 .card .card-header {
	padding: 15px 15px 0;
	border: 0;
 }
 
 .card .card-header:not([data-background-color]) {
	background-color: transparent;
 }
 
 .card .card-header .card-title {
	margin-top: 10px;
 }
 
 .card .image {
	overflow: hidden;
	height: 200px;
	position: relative;
 }
 
 .card label {
	font-size: 0.8571em;
	margin-bottom: 5px;
	color: #9A9A9A;
 }
 
 .card .card-footer {
	background-color: transparent;
	border: 0;
 }
 
 .card .card-footer .stats i {
	margin-right: 5px;
	position: relative;
	top: 2px;
 }
 
 .card .card-footer .btn {
	margin: 0 !important;
 }
 
 .btn-round-icon,
 .btn-round-icon:active,
 .btn-round-icon:visited {
	background-color: #FFFFFF !important;
	color: $primary-color !important;
	border: 2px solid $primary-color !important;
	height: 3.6rem !important;
	min-width: 3.6rem !important;
	width: 3.6rem !important;
	padding: 0 !important;
	overflow: hidden !important;
	position: relative !important;
	line-height: 3.6rem !important;
	border-radius: 50% !important;
	margin: .4rem 1rem;
}

.fab {
	font-family: Font Awesome\ 5 Brands;
}

.btn-round-icon:hover {
	color: #fff !important;
	background-color: $primary-color !important;
}