$footer-min-height: 160px;

#footer-container {
    background-color: $footer-background-color;
    color: $light-font-color !important;
    line-height: 1.7em;

    @media screen and (max-width: 768px) {
        .texto-dhoe {
            margin: 0 24px;
        }
    }
}

#sitemap {
    text-align: left;
    
    a, a:active, a:visited, a:link {
        color: $primary-font-color;
        text-decoration: none;
    }
    
    a:hover {
        color: $secondary-color !important;
        text-decoration: none;
    }
}

#form-captacao input,
#email-newsletter-input,
#exampleInputEmail1 {
    border-radius: 0.3rem !important;
}

#footer-links-redes-sociais {
    width: fit-content;
    width: -moz-fit-content;
}

#footer-links-redes-sociais, #footer-links-redes-sociais a, #footer-links-redes-sociais a:hover {
    color: #ffffff !important;
    font-size: 35px;
}