#dashboard {
	position: relative;
	top: 0;
	width: 100vw;

	.container-fluid {
		padding-right: 15px;
		padding-left: 15px;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: justify;
		justify-content: space-between;
		position: relative;
		color: white;
		padding-top: 1em;
	}

	.navbar-toggler {
		background-color: transparent !important;
		border: 0;
	}

	.navbar-toggler-bar {
		display: block;
		position: relative;
		width: 22px;
		height: 1px;
		border-radius: 1px;
		background: #FFFFFF;
	 }
	 .navbar-toggler-bar+.navbar-toggler-bar {
		margin-top: 7px;
	 }
	 .navbar-toggler-bar+.navbar-toggler-bar.navbar-kebab {
		margin-top: 3px;
	 }
	 .navbar-toggler-bar.bar2 {
		width: 17px;
		transition: width .2s linear;
	 }
	 .toggled .navbar-toggler-bar {
		width: 24px;
	 }
	 .toggled .navbar-toggler-bar+.navbar-toggler-bar {
		margin-top: 5px;
	 }

	#navbar-text {
		text-transform: uppercase;
		padding-top: 1rem;
		padding-bottom: 0.5rem;
		line-height: 1.625rem;
	}

	.panel-header {
		height: 80px;
		padding-bottom: 45px;
		background-color: $dashboard-menu-primary-color;
		//background: -o-linear-gradient(315deg, $dashboard-menu-primary-color 0%, $dashboard-menu-secondary-color 90%);
		//background: linear-gradient(135deg, $dashboard-menu-primary-color 0%, $dashboard-menu-secondary-color 90%);
		position: relative;
		overflow: hidden;
	}
}

#dashboard-conteudo {
	width: 100%;
	background-color: #fafbfe;
}

.menu-open {
	position: relative;
	float: right;
	width: calc(100% - 260px) !important;
}

.menu-open-mobile {
	width: 100% !important;
	-ms-transform: translateX(260px);
	-webkit-transform: translateX(260px);
	transform: translateX(260px);
}

.navbar-toggler, #navbar-text {
	color: $primary-font-color !important;
}